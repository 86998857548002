import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { BaseTooltipProps } from "../../interfaces/componentInterFaces";

export default function BaseTooltip({
  toolTipData,
  tooltipPoint,
  handleObjectHoveringCount,
  hoveringRectData,
  imageScale,
  svgRef,
  handleMouseEnter = (e, idx) => {},
  handleMouseOut = (e, idx) => {},
  pathIdx,
  svgImageRef,
  handleClose,
}: BaseTooltipProps) {
  const [tooltipPosition, setTooltipPosition] = useState<any>({});

  const tooltipBaseRef = useRef<HTMLDivElement | null>(null);

  const [clickOptions, setClickOptions] = useState<number | null>(null);

  // refs
  const imageRef = useRef<HTMLImageElement>(null);
  const headRef = useRef<HTMLHeadingElement>(null);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (!tooltipBaseRef.current) {
      return;
    }
    if (!hoveringRectData) {
      return;
    }
    if (!tooltipPoint) {
      return;
    }
    if (!svgRef.current) {
      return;
    }
    if (!svgImageRef.current) {
      return;
    }

    const tooltipRect = tooltipBaseRef.current.getBoundingClientRect();

    if (!tooltipRect) {
      return;
    }
    const svgRect = svgImageRef.current.getBoundingClientRect();

    // Calculate the maximum left and top positions to keep the tooltip within the parent
    const maxLeft =
      svgRect.width - tooltipBaseRef.current.offsetWidth + svgRect.left;
    const maxTop =
      svgRect.height - tooltipBaseRef.current.offsetHeight + svgRect.top;

    // Calculate the new tooltip left and top positions

    let tooltipLeft = Math.min(maxLeft, tooltipPoint.x);
    let tooltipTop = Math.min(maxTop, tooltipPoint.y);

    if (!tooltipLeft && !tooltipTop) {
      setTooltipPosition({});
    } else {
      setTooltipPosition({
        left: tooltipLeft + 5,
        top: tooltipTop + 5,
        width: tooltipBaseRef.current.offsetWidth - 5,
        height: tooltipBaseRef.current.offsetHeight - 5,
      });
    }
  }, [tooltipPoint?.x, tooltipPoint?.y]);

  const handleKeyPress = useCallback((e: any) => {
    if (e.key === "h") {
      setClickOptions((prev) => {
        if (prev === null) {
          return 0;
        }
        if (prev === 3) {
          return 0;
        }
        return prev + 1;
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (clickOptions === null) {
      return;
    }

    if (clickOptions === 0) {
      if (!imageRef.current) {
        return;
      }
      imageRef.current.focus();
      linkRef?.current?.blur();
      console.log("current", imageRef.current);
      console.log("focsing", imageRef.current.focus());
    }
    if (clickOptions === 1) {
      if (!headRef.current) {
        return;
      }
      headRef.current.focus();
    }
    if (clickOptions === 2) {
      if (!descriptionRef.current) {
        return;
      }
      descriptionRef.current.focus();
    }
    if (clickOptions === 3) {
      if (!linkRef.current) {
        setClickOptions(0);
        return;
      }
      linkRef.current.focus();
    }
    console.log("clickoption", clickOptions);
  }, [clickOptions]);

  useEffect(() => {
    return () => {
      setTooltipPosition(null);
    };
  }, []);

  // console.log("toolTipData", toolTipData);
  // console.log("baseTooltipWorking");
  const checkMObile = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    )
      return true;
    else return false;
  };

  return (
    <div
      id="toolTip"
      ref={tooltipBaseRef}
      className="absolute w-max cursor-pointer"
      style={{
        // visibility: "hidden",
        left: tooltipPosition?.left,
        top: tooltipPosition?.top,
        // width: tooltipPosition?.width,
        // height: tooltipPosition?.height,

        // pointerEvents: checkMObile() ? "auto" : "none",
      }}
      onClick={() => {
        handleObjectHoveringCount(toolTipData.objectUUID, "click");
        // console.log("clicking");
        if (!!toolTipData?.redirect_url === true) {
          window.open(toolTipData?.redirect_url);
        }
      }}
      onMouseEnter={(e) => {
        handleMouseEnter(e, pathIdx);
      }}
      onMouseLeave={(e) => {
        handleMouseOut(e, pathIdx);
      }}>
      <div className="w-max relative bg-[#fcfcfc] !important h-max flex px-[8px] py-[8px] rounded-md  shadow-md ">
        <div
          id="card-title-img"
          className=" min-w-[70px] w-max max-w-[80px] flex justify-center items-center">
          <div
            className={`w-full my-auto flex justify-center items-center ${
              clickOptions === 0 ? "p-1 border border-black" : ""
            }`}>
            <img
              tabIndex={0}
              ref={imageRef}
              className={`focus-0 max-h-[80px]`}
              src={
                toolTipData?.meta_image_link || toolTipData?.thumbnail_url || ""
              }
              alt={`thumbnail of ${toolTipData?.title}`}
            />
          </div>
        </div>

        <div
          className="min-w-[130px] w-max max-w-[230px] flex flex-col "
          style={{
            marginLeft: 10,
            marginRight: 2,
          }}>
          <h1
            tabIndex={1}
            aria-description={toolTipData?.title}
            ref={headRef}
            className={`focus-1 h-max font-bold text-[14px]  ${
              clickOptions === 1 ? "border border-black" : ""
            }`}
            style={{
              wordWrap: "break-word",
            }}>
            {toolTipData?.title?.length > 75
              ? `${toolTipData?.title.slice(0, 75)} ...`
              : toolTipData?.title}
          </h1>
          <p
            tabIndex={2}
            aria-description={toolTipData?.description}
            ref={descriptionRef}
            className={`focus-2 h-max font-light text-[12px] flex-grow pl-[0.8px] ${
              clickOptions === 2 ? "border border-black" : ""
            }`}
            style={{
              wordWrap: "break-word",
            }}>
            {toolTipData?.description?.split(" ")?.length > 50
              ? `${toolTipData?.description?.split(" ").slice(0, 50)} ...`
              : toolTipData?.description}
          </p>
          <div
            style={{
              wordWrap: "break-word",
            }}
            className="h-max"
            onClick={() => {}}>
            {!!toolTipData?.redirect_url === true && (
              <a
                tabIndex={3}
                ref={linkRef}
                style={{
                  wordWrap: "break-word",
                }}
                href={toolTipData?.redirect_url || "https://google.com"}
                className="focus-3 underline font-bold text-[12px]"
                target="_blank"
                rel="noreferrer">
                {toolTipData?.cta_name?.length > 30
                  ? `${toolTipData?.cta_name.slice(0, 30)} ...`
                  : toolTipData?.cta_name}
              </a>
            )}
          </div>
        </div>
        <div
          className="w-[15px] h-[15px] rounded-full   top-2 right-[5px] cursor-pointer flex justify-center items-center bg-white"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            console.log("adfasdf");
            handleClose(e, pathIdx);
          }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="100"
            height="100"
            viewBox="0 0 50 50">
            <path d="M 25 2 C 12.309534 2 2 12.309534 2 25 C 2 37.690466 12.309534 48 25 48 C 37.690466 48 48 37.690466 48 25 C 48 12.309534 37.690466 2 25 2 z M 25 4 C 36.609534 4 46 13.390466 46 25 C 46 36.609534 36.609534 46 25 46 C 13.390466 46 4 36.609534 4 25 C 4 13.390466 13.390466 4 25 4 z M 32.990234 15.986328 A 1.0001 1.0001 0 0 0 32.292969 16.292969 L 25 23.585938 L 17.707031 16.292969 A 1.0001 1.0001 0 0 0 16.990234 15.990234 A 1.0001 1.0001 0 0 0 16.292969 17.707031 L 23.585938 25 L 16.292969 32.292969 A 1.0001 1.0001 0 1 0 17.707031 33.707031 L 25 26.414062 L 32.292969 33.707031 A 1.0001 1.0001 0 1 0 33.707031 32.292969 L 26.414062 25 L 33.707031 17.707031 A 1.0001 1.0001 0 0 0 32.990234 15.986328 z"></path>
          </svg>
        </div>
      </div>
    </div>
  );
}
