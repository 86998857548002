import axios from "axios";

// const baseURL = 'http://52.66.195.125/api' // http://52.66.195.125/  http://52.66.195.125:8080

const buildEnv = process.env.REACT_APP_STAGE as "prod" | "dev" | "local";
console.log(process.env);
const base = {
  prod: "https://prod-api.spat.media/api",
  dev: "https://dev-api.spat.media/api",
  local: "http://127.0.0.1:5000/api",
};
const baseURL = base[buildEnv];

export const Axios = axios.create({ baseURL: baseURL });

// Axios.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       config.headers["Authorization"] = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     Promise.reject(error);
//   }
// );

export const urls = {
  embedRetrieve: "/embed_data/retrieve",
  viewCount: "/view-count",
  clickHoverCount: "/click-hover-count",
  getIpAddress: "/get-ip-address",
};

const bucketUrls = {
  prod: "https://data.spat.media",
  dev: "https://data.spat.media",
  local: "https://data.spat.media",
};

export const currentBucket: string = bucketUrls[buildEnv] as string;
