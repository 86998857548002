import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { UUID } from "crypto";
import * as _ from "underscore";

//
import {
  AddViewCount,
  CountObjectHoverAndClick,
  FetchRetrieveEmbed,
  GetIpAddress,
} from "../../urls/retrieveEmbedRoutes";

// interface
import {
  ImageObjectsEntity,
  ViewCountDataProps,
} from "../../interfaces/apiResponseInterfaces";
import {
  CursorPositionProps,
  HostDataProps,
  ObjectArrProps,
  ScaleDataProps,
} from "../../interfaces/stateInterfaces";

// custom hooks
import useKeyPress from "../../customHooks/useKeyPress";

import ResponsiveSvgToolTip from "../Tooltips/responsiveSvgToolTip";
import BaseTooltip from "../Tooltips/BaseToolTip";

function Preview() {
  const [searchParams] = useSearchParams();
  let showType = searchParams.get("showType");
  if (!!showType === false) {
    showType = "published";
  }

  const handlePressFn = (e: KeyboardEvent) => {
    setClickOptions(`tab-${Math.random()}`);
  };
  // const jd = useKeyPress(["j"], handlePressFn);

  const imageId = searchParams.get("image_id");

  //   refs
  const baseRef = useRef<HTMLDivElement | null>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [pathRefs, setPathRefs] = useState<
    Array<React.RefObject<SVGPathElement> | null>
  >([]);

  const svgImageRef = useRef<SVGImageElement | null>(null);

  // extra notifies ref
  const rollNotifyRef = useRef<HTMLDivElement>(null);

  // !  states

  //   conditions states
  const [imageStatus, setImageStatus] = useState<
    "published" | "draft" | "preview" | null
  >(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  // key options
  const [clickOptions, setClickOptions] = useState<String | null>(null);

  // data states
  const [scaleData, setScaleData] = useState<ScaleDataProps | null>(null);
  const [objectArr, setObjectArr] = useState<ObjectArrProps[] | null>(null);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [hoveredObjectData, setHoveredObjectData] =
    useState<ObjectArrProps | null>(null);
  const [prevHoveredPathIdx, setPrevHoveredPathIdx] = useState<number | null>(
    null
  );
  const [hoveredPathIdx, setHoveredPathIdx] = useState<number | null>(null);
  const [cursorPosition, setCursorPosition] =
    useState<CursorPositionProps | null>(null);
  const [isCursorOverTooltip, setCursorOverTooltip] = useState(false);
  const [isCursorOverPath, setCursorOverPath] = useState(false);

  // interaction related properties
  const [hostData, sethostData] = useState<HostDataProps | null>(null);
  const [viewCountData, setViewCountData] = useState<ViewCountDataProps | null>(
    null
  );

  // intervalRoller
  const [intervalRoller, setIntervalRoller] = useState<number>(0);

  const [startInterval, setStartInterval] = useState<boolean>(false);

  const [rollerIntervalId, setRollerIntervalId] = useState<NodeJS.Timer | null>(
    null
  );
  const [alreadyHovered, setAlreadyHovered] = useState<boolean>(false);

  const [resetTimeoutId, setResetTimeoutId] = useState<NodeJS.Timer | null>(
    null
  );

  // image data
  const [imageExpired, setImageExpired] = useState<boolean>(false);
  const [imageBlob, setImageBlob] = useState<string | null>(null);
  const [imageStyle, setImageStyle] = useState<object | null>(null);

  // extra notify states
  const [rolloverNotifyStyle, setRollOverNotifyStyle] = useState<any | null>(
    {}
  );

  // toolTip helping states
  const [mouseOutTmId, setMouseOutTmId] = useState<NodeJS.Timer | null>(null);

  const [showToller, setShowRoller] = useState<boolean>(false);

  // rotate embed for
  const [rotationChanged, setRotationChanged] = useState<number | null>(null);

  // for keybinding

  const [closeCard, setCloseCard] = useState<boolean | null>(null);

  function getHoveringRectData(
    aPathRef: React.RefObject<SVGPathElement> | null
  ) {
    const pathElement = aPathRef?.current;
    if (!pathElement) {
      return null;
    }
    const pathRect = pathElement.getBoundingClientRect();
    return pathRect;
  }

  function getPathRefLeftRight(
    aPathRef: React.RefObject<SVGPathElement> | null
  ) {
    const pathElement = aPathRef?.current;
    if (!pathElement) {
      return {
        rectLeft: 0,
        rectTop: 0,
      };
    }
    const pathRect = pathElement.getBoundingClientRect();
    return {
      rectLeft: pathRect.left,
      rectTop: pathRect.right,
    };
  }

  const loadImage = (imgUrl: string) => {
    const url = new URL(imgUrl);

    try {
      const img = new Image();
      img.src = url.href;
      img.onload = () => {
        if (img.naturalWidth < img.naturalHeight) {
          setImageStyle({
            width: "auto",
            height: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
          });
        } else {
          setImageStyle({
            width: "100%",
            height: "auto",
            maxWidth: "100%",
            maxHeight: "100%",
          });
        }
      };
    } catch (error) {
      console.log(error);
    }
  };

  // function settingImageExpiry(response) {
  //   if (showType === "published") {
  //   }
  // }

  async function fetchingRetrieve() {
    try {
      const response = await FetchRetrieveEmbed({
        params: {
          image_id: imageId as UUID,
        },
      });

      //   if (!response.data.is_active) {
      //     return;
      //   }
      //   imageData assertions
      setImageUrl(
        response.data.image_compressed_url || response.data.image_url
      );
      setImageStatus(response.data.status);

      loadImage(response.data.image_compressed_url || response.data.image_url);
      // imageLoadingToDataUrl(response.data.image_compressed_url);

      // settingImageExpiry(response);

      // setImageExpired(
      //   showType === "published" &&
      //     ((response.data.status === "published" &&
      //       (response.data.is_active === false ||
      //         new Date(response.data.expiry_date) < new Date())) ||
      //       response.data.status === "completed" ||
      //       response.data.deleted === true)
      // );

      // ! image expiration conditons
      let isImageExpiredFlag = false;

      if (showType === "published") {
        if (response.data.status !== "published") {
          isImageExpiredFlag = true;
        }
        if (response.data.is_active === false) {
          isImageExpiredFlag = true;
        }
        if (new Date(response.data.expiry_date) < new Date()) {
          isImageExpiredFlag = true;
        }
        if (response.data.status === "completed") {
          isImageExpiredFlag = true;
        }
        if (response.data.deleted === true) {
          isImageExpiredFlag = true;
        }
      }

      if (!response.data?.image_objects?.[0]) {
        isImageExpiredFlag = true;
      }
      setImageExpired(isImageExpiredFlag);
      //

      // getImageAsBase64(response.data.image_url);
      const splitValue = (
        response.data?.image_compressed_url || response.data.image_url
      ).split(".");

      const blobData = `data:image/${
        splitValue[splitValue.length - 1]
      };base64,${response.data.model_type}`;
      setImageBlob(blobData);
      console.log(blobData);
      //   objects data assertion
      const mappedImageObjects: ObjectArrProps[] = response.data.image_objects
        .filter(
          (eachObject: any) =>
            eachObject?.deleted === false && eachObject.is_active === true
        )
        .map((eachObjects: ImageObjectsEntity): ObjectArrProps => {
          const dd: ObjectArrProps = {
            ...eachObjects,
            isHovered: false,
            ref: null,
            boundingBox: null,
          };

          return dd;
        })
        .sort((a: ImageObjectsEntity, b: ImageObjectsEntity) => {
          if (b?.area < a?.area) {
            return -1; // b should come before a
          } else if (b?.area > a?.area) {
            return 1; // a should come before b
          } else {
            return 0; // a and b have equal areas, their order doesn't matter
          }
        });

      if (mappedImageObjects) {
        setScaleData({
          xScale:
            mappedImageObjects[0].model_scale.width *
            mappedImageObjects[0].model_scale.upload_scale,
          yScale:
            mappedImageObjects[0].model_scale.height *
            mappedImageObjects[0].model_scale.upload_scale,
        });
      }
      setObjectArr(mappedImageObjects);
    } catch (error) {
      console.log(error);
    }
  }

  //! useEffects

  // loading false

  useEffect(() => {
    if (!objectArr?.length) {
      return;
    }
    if (!!imageBlob === false) {
      return;
    }
    if (!!imageStatus === false) {
      return;
    }
    if (imageExpired === false && showToller === false) {
      return;
    }

    if (showType === "published" && imageStatus === "published") {
      if (!viewCountData) {
        return;
      }
    }
    setIsLoading(false);
  }, [
    objectArr,
    imageBlob,
    viewCountData,
    showType,
    imageStatus,
    showToller,
    imageExpired,
  ]);

  // Initialize the pathRefs array
  useEffect(() => {
    if (!objectArr) {
      return;
    }
    setPathRefs(
      Array.from({ length: objectArr.length }, () =>
        React.createRef<SVGPathElement>()
      )
    );
  }, [objectArr]);

  useEffect(() => {
    if (
      showType === "draft" ||
      showType === "published" ||
      showType === "preview"
    ) {
      if (imageId === null) {
        alert("image id needed");
        return;
      }
      // console.log("host");
      fetchingRetrieve();
      if (hostData) {
        return;
      }
      // console.log("this is host data", hostData);
      handleSettingHostData();
      // console.log("rendingering");
    }
  }, [showType, imageId]);

  useEffect(() => {
    if (hostData === null) {
      return;
    }
    if (showType !== "published" || imageStatus !== "published") {
      return;
    }
    if (showType === "published" && imageStatus === "published") {
      loadViewCount();
    }
  }, [hostData, imageStatus]);

  // INTERVAL EFFECTS

  useEffect(() => {
    // console.log("intervalRoller", intervalRoller);
    if (!objectArr) {
      return;
    }
    if (!pathRefs?.[0]) {
      return;
    }
    if (!startInterval) {
      return;
    }
    //
    const pathRef = pathRefs[intervalRoller];
    if (pathRef && pathRef.current) {
      const pathElement = pathRef.current;
      pathElement.style.strokeOpacity = "1"; // Restore original stroke opacity

      const timeoutId = setTimeout(
        (previousRollerId, previousPathRefCurrent: any) => {
          if (previousPathRefCurrent?.current?.style) {
            previousPathRefCurrent.current.style.strokeOpacity = "0";
          }
        },
        3000 - 3,
        intervalRoller,
        pathRef
      );
      setResetTimeoutId(timeoutId);
    }
  }, [intervalRoller, pathRefs]);

  useEffect(() => {
    if (!startInterval) {
      return;
    }
    if (!objectArr) {
      return;
    }

    // console.log("dog");
    startingIntervalFun();
  }, [startInterval]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!objectArr) {
      return;
    }
    if (!svgRef.current) {
      return;
    }
    setAlreadyHovered(false);
    setStartInterval(true);
    // console.log("ning");
  }, [isLoading, objectArr, svgRef.current]);

  useEffect(() => {
    if (isLoading === false) {
      return;
    }
    LoadRollerNotify();
  }, [isLoading, objectArr, svgRef.current, rollNotifyRef.current]);

  useEffect(() => {
    if (!svgImageRef.current) {
      return;
    }
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          // console.log("entry", entry);
          if (entry.isIntersecting) {
            console.log("dodododo");
            if (svgImageRef.current) observer.unobserve(svgImageRef.current);
          }
        });
      },
      { threshold: 0.5 }
    );

    observer.observe(svgImageRef.current);

    // console.log("from this cureent inag", svgImageRef.current);
    return () => {
      if (svgImageRef.current) {
        observer.unobserve(svgImageRef.current);
      }
    };
  }, [svgImageRef.current]);

  useEffect(() => {
    if (hoveredPathIdx === null) {
      return;
    }

    handleToolMouseOptions(hoveredPathIdx);
  }, [rotationChanged]);

  useEffect(() => {
    const handleOrientationChange = () => {
      setRotationChanged(Math.floor(Math.random() * 11) + 1);
    };

    console.log("Component mounted");
    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      console.log("Component unmounted");
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  const handleKeyPress = useCallback((e: any) => {
    if (e.key === "c") {
      if (hoveredPathIdx !== null) handleToolMouseOptions(hoveredPathIdx);
      setCloseCard(true);
    }
    if (e.key === "j") {
      setClickOptions(`tab-${Math.random()}`);
    }
  }, []);

  useEffect(() => {
    // const handleKeyDown = (e: any) => {
    //   if (e.key === "c") {
    //     if (hoveredPathIdx !== null) handleToolMouseOptions(hoveredPathIdx);
    //     setCloseCard(true);
    //   }
    // };
    document.body.focus();

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  useEffect(() => {
    if (closeCard !== null && hoveredPathIdx !== null && closeCard === true) {
      handleToolMouseOptions(hoveredPathIdx);
      setCloseCard(null);
    }
  }, [closeCard]);

  useEffect(() => {
    console.log("it is from click options", clickOptions);
    if (clickOptions === null) return;
    console.log("comming", `this is path index${hoveredPathIdx}`);
    let changePathIdx = 0;

    if (intervalRoller !== null) {
      // if (changePathIdx !== 0) {
      //   changePathIdx = 0;
      // }
      changePathIdx = intervalRoller + 1;

      if (objectArr && changePathIdx >= objectArr.length) {
        changePathIdx = 0;
      }
    }
    console.log("every time changing path", changePathIdx);

    intervalManager(changePathIdx);
    setClickOptions(null);

    const eachObjectData = objectArr?.[changePathIdx];
    if (!eachObjectData) {
      return;
    }

    svgPathGroupHandleMouseEnter(eachObjectData, changePathIdx);
  }, [clickOptions]);

  //   mouse Actions
  function svgPathGroupHandleMouseEnter(
    eachObjectData: ObjectArrProps,
    pathIndex: number
  ) {
    // interval conditions
    setStartInterval(false);

    if (rollerIntervalId) {
      if (resetTimeoutId) clearTimeout(resetTimeoutId);
      clearInterval(rollerIntervalId);

      setRollerIntervalId(null);
    }

    pathRefs.forEach((eachPathRef) => {
      if (eachPathRef?.current) {
        eachPathRef.current.style.strokeOpacity = "0";
      }
    });

    setMouseOutTmId((perv) => {
      if (perv) {
        clearTimeout(perv);
      }
      return null;
    });

    const pathElement = pathRefs?.[intervalRoller]?.current;
    if (pathElement) pathElement.style.strokeOpacity = "0";

    // setHoveredPathIdx(pathIndex);
    setHoveredObjectData(eachObjectData);
    setCursorOverPath(true);

    //
    const pathRef = pathRefs[pathIndex];
    if (pathRef && pathRef.current) {
      const pathElement = pathRef.current;
      pathElement.style.strokeOpacity = "1"; // Restore original stroke opacity
    }

    setHoveredPathIdx((prev) => {
      // console.log(prev, pathIndex);
      if (prev !== pathIndex) {
        console.log("1 from the real");
        handleObjectHoveringCount(eachObjectData.id as UUID, "hover");
      }
      return pathIndex;
    });

    setAlreadyHovered(true);
  }
  function svgPathGroupHandleMouseOut(
    eachObjectData: ObjectArrProps,
    pathIndex: number
  ) {
    // if (isCursorOverTooltip&&) {
    //   return;
    // }
    setPrevHoveredPathIdx(null);
    // console.log("somdffnadsufn");
    setHoveredPathIdx(null);
    setHoveredObjectData(null);
    setCursorOverPath(false);

    const pathRef = pathRefs[pathIndex];

    if (pathRef?.current) {
      const pathElement = pathRef.current;
      pathElement.style.strokeOpacity = "0"; // Restore original stroke opacity
    }

    setStartInterval(true);
  }

  function svgPathGroupHandleMouseMove(
    e: React.MouseEvent<SVGGElement, MouseEvent>
  ) {
    const svgElement = svgRef.current;
    if (svgElement) {
      // Access rendering properties here
      const bbox = svgElement.getBBox();
      // console.log("X:", bbox.x);
      // console.log("Y:", bbox.y);
      // console.log("Width:", bbox.width);
      // console.log("Height:", bbox.height);
    }

    setCursorPosition({
      x: e.clientX,
      y: e.clientY,
    });
  }

  function isTouchDevice() {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.maxTouchPoints > 0
    );
  }

  function svgPathGroupHandleMouseUp(
    eachObjectData: ObjectArrProps,
    pathIndex: number
  ) {
    if (!isTouchDevice()) {
      handleObjectHoveringCount(eachObjectData.id as UUID, "click");
      if (!!eachObjectData?.title_card?.[0]?.redirect_url === true) {
        window.open(eachObjectData?.title_card?.[0]?.redirect_url);
      }
    }
  }

  // handle interaction

  async function handleObjectHoveringCount(
    objectUUID: UUID,
    type: "click" | "hover"
  ) {
    console.log("times");
    // console.log(`${objectUUID}:${type}`);
    // console.log(imageStatus, showType);
    if (imageStatus !== "published" || showType !== "published") {
      return;
    }
    console.log("comming");
    if (viewCountData === null) {
      alert("view count data not available");
      return;
    }

    try {
      const response = await CountObjectHoverAndClick({
        payload: {
          object_id: objectUUID,
          type_of_data: type,
          view_id: viewCountData.id,
        },
      });

      // console.log("counted", type, response.data);
    } catch (error) {
      console.log(
        "something went wrong in CountObjectHoverAndClick api",
        error
      );
    }
  }

  async function handleSettingHostData() {
    const res = await GetIpAddress();

    //

    // console.log("finding the host data", hostData);

    sethostData({
      client: res.data.ip,
      host:
        window.location !== window.parent.location
          ? document.referrer
          : document.location.href,
    });

    //
  }

  async function loadViewCount() {
    if (hostData === null) {
      return;
    }
    const response = await AddViewCount({
      payload: {
        host_url: hostData.host,
        image_id: imageId as UUID,
        user_ip: hostData.client,
      },
    });

    setViewCountData(response.data);
  }

  // interval functions

  function startingIntervalFun() {
    if (!startInterval) {
      return;
    }
    if (!objectArr) {
      return;
    }
    function intervalCallback(len: number) {
      setIntervalRoller((prev) => {
        // console.log("prev", prev);

        const clonePrev = prev + 1;
        // console.log("rooler", clonePrev);

        if (len === 1) {
          if (clonePrev === 1) {
            return 1;
          }
          if (clonePrev === 2) {
            return 0;
          }
        }

        if (clonePrev > len - 1) {
          return 0;
        }

        return clonePrev;
      });
    }

    // setOneEntered(true);
    // function singleInterval() {
    //   setIntervalRoller(null);
    //   setIntervalRoller(9);
    // }

    const intervalId = setInterval(intervalCallback, 3000, objectArr.length);

    setRollerIntervalId(intervalId);
  }

  const getImageAsBase64 = async (imageUrl: string) => {
    try {
      // Infer the MIME type based on the file extension
      const extension = imageUrl.split(".").pop();
      const mimeTypes: any = {
        png: "image/png",
        jpeg: "image/jpeg",
        jpg: "image/jpeg",
        gif: "image/gif",
        // Add more as needed
      };
      const mimeType = mimeTypes[extension!.toLowerCase()];

      const response = await axios.get(imageUrl, {
        responseType: "arraybuffer",
      });

      if (response.status === 200) {
        const arrayBuffer = response.data;
        const blob = new Blob([arrayBuffer], {
          type: mimeType || "application/octet-stream",
        });
        const reader = new FileReader();

        reader.onload = () => {
          const base64ImageData = reader.result;
          // console.log(base64ImageData);
          setImageBlob(base64ImageData as string); // The image data as base64
        };

        reader.readAsDataURL(blob);
      } else {
        const message = `HTTP error! Status: ${response.status}`;
        // throw new Error(message);
        console.log(message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // roller notify support functions
  function LoadRollerNotify() {
    if (!objectArr?.length) {
      return;
    }
    if (!svgRef.current) {
      return;
    }
    if (!rollNotifyRef.current) {
      return;
    }
    if (!svgImageRef.current) {
      return;
    }

    const svgImageRect = svgImageRef?.current?.getBoundingClientRect();

    if (!svgImageRect) {
      return;
    }

    window.addEventListener("resize", () => {
      const svgImageRect = svgImageRef?.current?.getBoundingClientRect();
      if (!svgImageRect) {
        return;
      }

      setRollOverNotifyStyle({
        top:
          svgImageRect.top +
          svgImageRect.height -
          (rollNotifyRef?.current?.offsetHeight || 0),
        // opacity: 1,
      });
    });

    setRollOverNotifyStyle({
      top:
        svgImageRect.top +
        svgImageRect.height -
        3 -
        rollNotifyRef.current.offsetHeight,
      opacity: 1,
    });

    setShowRoller(true);
    setTimeout(() => {
      setShowRoller(false);
    }, 3000);
  }

  function handleToolMouseOptions(pathIdx: number) {
    const selectedPathRef = pathRefs[pathIdx]?.current;
    if (selectedPathRef) {
      selectedPathRef.style.strokeOpacity = "0";
    }

    setHoveredPathIdx(null);
    setHoveredObjectData(null);
    setCursorOverPath(false);

    setStartInterval(true);
  }

  function imageLoadingToDataUrl(imgUrl: string) {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image onto the canvas
      ctx!.drawImage(img, 0, 0);

      const splitValue = imgUrl.split(".");
      // Get image data as XML (Base64-encoded PNG)
      const imageData = canvas.toDataURL(`image/PNG}`);

      alert(imageData);

      // This will log an SVG representation containing the image data
      // You can further manipulate or use this SVG as needed
    };

    img.src = imgUrl;
  }

  // utils functinons

  function intervalManager(pathIndex: number) {
    setPrevHoveredPathIdx(null);
    setHoveredPathIdx(null);
    setHoveredObjectData(null);
    setCursorOverPath(false);

    pathRefs.forEach((eachPathRef) => {
      if (eachPathRef?.current) {
        eachPathRef.current.style.strokeOpacity = "0";
      }
    });

    // for blink the path
    setStartInterval(false);
    if (rollerIntervalId) {
      if (resetTimeoutId) clearTimeout(resetTimeoutId);
      clearInterval(rollerIntervalId);

      setRollerIntervalId(null);
    }

    const pathElement = pathRefs?.[intervalRoller]?.current;
    if (pathElement) pathElement.style.strokeOpacity = "0";

    setMouseOutTmId((perv) => {
      if (perv) {
        clearTimeout(perv);
      }
      return null;
    });

    const pathRef = pathRefs[pathIndex];

    if (pathRef && pathRef.current) {
      const pathElement = pathRef.current;
      pathElement.style.strokeOpacity = "1"; // Restore original stroke opacity
    }

    console.log("this is path index ", pathIndex);

    setIntervalRoller(pathIndex);
  }

  return (
    <div
      className="w-screen h-screen max-w-[100%] max-h-[1x00%] relative flex justify-center items-center"
      ref={baseRef}>
      {!imageExpired && objectArr?.length && (
        <svg
          ref={svgRef}
          className="w-full h-auto max-h-[100%]"
          style={imageStyle || {}}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${scaleData?.xScale} ${scaleData?.yScale}`}>
          <image
            ref={svgImageRef}
            width="100%"
            height="100%"
            xlinkHref={imageUrl || ""}
            clipPath={`url(#clip-path${Math.random()})`}
            onLoad={(event) => {
              LoadRollerNotify();
              // console.log("jd");
              // console.log("onloaded", event.target);
            }}
          />
          {isLoading === false &&
            objectArr.map((eachObject, objectIdx: number) => {
              return (
                <g
                  key={"g" + objectIdx}
                  //   mouseEvents
                  onMouseEnter={(event) => {
                    setPrevHoveredPathIdx(objectIdx);
                    // console.log("entering");
                    svgPathGroupHandleMouseEnter(eachObject, objectIdx);
                  }}
                  onMouseOut={() => {
                    const tmCb = (
                      eachObject: ObjectArrProps,
                      objectIdx: number
                    ) => {
                      // console.log("outing");
                      svgPathGroupHandleMouseOut(eachObject, objectIdx);
                    };
                    const tmId = setTimeout(tmCb, 150, eachObject, objectIdx);
                    setMouseOutTmId(tmId);
                  }}
                  onMouseMove={(e) => {
                    //   console.log("mouseMoving");
                    svgPathGroupHandleMouseMove(e);
                  }}
                  onMouseUp={() => {
                    svgPathGroupHandleMouseUp(eachObject, objectIdx);
                  }}>
                  <path
                    //  bbWidthRatio > 0.5&&
                    id={"mask-gradient" + objectIdx}
                    className={`mask-gradient ${
                      window.innerWidth < 768 ? "hidden" : ""
                    }`}
                    d={eachObject.svg_string}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeOpacity="0"
                    fillOpacity="0"
                    fill={`url(#gradient${objectIdx})`}
                  />
                  <path
                    key={"mask-path" + objectIdx}
                    ref={pathRefs[objectIdx]}
                    id={"mask-path" + objectIdx}
                    className="mask-path cursor-pointer"
                    d={eachObject.svg_string}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeOpacity={0}
                    fillOpacity="0"
                    stroke="white"
                    strokeWidth="3"
                    filter={`url(#glow${objectIdx})`}
                  />
                  <filter
                    id={"glow" + objectIdx}
                    x="-50%"
                    y="-50%"
                    width={"200%"}
                    height={"200%"}>
                    <feDropShadow
                      dx="0"
                      dy="0"
                      stdDeviation="2"
                      floodColor="white"
                    />
                    <feDropShadow
                      dx="0"
                      dy="0"
                      stdDeviation="4"
                      floodColor="hsl(112.37deg 56.19% 41.18%)"
                    />
                    <feDropShadow
                      dx="0"
                      dy="0"
                      stdDeviation="6"
                      floodColor="white"
                    />
                  </filter>
                </g>
              );
            })}
        </svg>
      )}
      {imageExpired && !isLoading && (
        <img
          src={imageUrl}
          alt="dynamic img"
          className="w-max h-max  max-h-[100%] max-w-[100%]"
        />
      )}
      {isLoading && (
        <div className="absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center">
          Loading...
        </div>
      )}
      {/* {svgRef &&
        hoveredPathIdx !== null &&
        pathRefs[hoveredPathIdx] !== null &&
        scaleData !== null && (
          <ResponsiveSvgToolTip
            pathRef={pathRefs[hoveredPathIdx] as RefObject<SVGPathElement>}
            svgRef={svgRef}
            scaleData={scaleData}
            toolTipData={
              hoveredObjectData?.title_card?.[0]
                ? {
                    ...hoveredObjectData.title_card?.[0],
                    objectUUID: hoveredObjectData.id,
                  }
                : null
            }
            cursorPosition={cursorPosition}
            // mouse events
            onMouseEnter={(status: boolean) => {
              console.log("entering into the cursor position");
              setCursorOverTooltip(true);
            }}
            onMouseOut={(status: boolean, pathIndex) => {
              setCursorOverTooltip(false);

              if (pathIndex !== hoveredPathIdx) {
                const pathRef = pathRefs[pathIndex];

                if (pathRef?.current) {
                  const pathElement = pathRef.current;
                  pathElement.style.strokeOpacity = "0"; // Restore original stroke opacity
                }
              }
            }}
            hoveredPathIdx={hoveredPathIdx}
          />
        )} */}

      {svgRef &&
        hoveredPathIdx !== null &&
        pathRefs[hoveredPathIdx]?.current !== null &&
        scaleData !== null && (
          <BaseTooltip
            hoveringRectData={getHoveringRectData(pathRefs[hoveredPathIdx])}
            toolTipData={
              hoveredObjectData?.title_card?.[0]
                ? {
                    ...hoveredObjectData.title_card?.[0],
                    objectUUID: hoveredObjectData.id,
                  }
                : null
            }
            tooltipPoint={{
              x: cursorPosition?.x ?? 0,
              y: cursorPosition?.y ?? 0,
              parentWidth: baseRef.current?.offsetWidth ?? 0,
              parentHeight: baseRef.current?.offsetHeight ?? 0,
              ...getPathRefLeftRight(pathRefs[hoveredPathIdx]),
            }}
            handleObjectHoveringCount={handleObjectHoveringCount}
            imageScale={{
              width: svgRef?.current?.width,
              height: svgRef?.current?.height,
            }}
            svgRef={svgRef}
            rotationChanged={4}
            handleMouseEnter={(e, pathIdx) => {
              // console.log("Entering in to base ToolTip");

              const selectedPathRef = pathRefs[pathIdx]?.current;
              if (selectedPathRef) {
                selectedPathRef.style.strokeOpacity = "1";
              }

              setMouseOutTmId((perv) => {
                if (perv) {
                  clearTimeout(perv);
                }
                return null;
              });
            }}
            handleMouseOut={(e, pathIdx) => {
              handleToolMouseOptions(pathIdx);
            }}
            handleClose={(e, pathIdx) => {
              handleToolMouseOptions(pathIdx);
            }}
            pathIdx={hoveredPathIdx}
            svgImageRef={svgImageRef}
          />
        )}

      <div
        ref={rollNotifyRef}
        id="rollNotify"
        className={`absolute w-max h-max px-[1%] py-[0.5%]   opacity-0 rolling-notify rounded-2xl flex justify-center flex-col items-center rollNotify`}
        style={{
          ...rolloverNotifyStyle,
          // ...rollerStyleSetting(),
          backgroundColor: "rgba(252, 252, 252, 0.7)",
          opacity: showToller && isCursorOverPath === false ? 1 : 0,
          zIndex: showToller && isCursorOverPath === false ? 1 : -1,
          pointerEvents: "none",
          padding: "clamp(10px, 1%, 30px) clamp(10px, 0.5%, 20px)",
        }}>
        <h1
          className="text-[2.5vw] font-extrabold text-center"
          style={{
            fontSize: "clamp(16px, 2.5vw, 30px)",
          }}>
          Interactive Image
        </h1>
        <p
          className="text-[1.7vw] text-center"
          style={{
            fontSize: "clamp(12px, 1.7vw, 22px)",
          }}>
          Click to Engage
        </p>
      </div>
    </div>
  );
}

export default Preview;
